var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "ma-2", attrs: { column: "" } },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c("v-data-iterator", {
            attrs: {
              items: _vm.assignmentsList,
              "hide-default-footer": _vm.assignmentsList.length == 0
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { color: "primary", dense: "" } },
                      [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v("Employee Assignments")
                        ]),
                        _vm.assignmentsList.length
                          ? _c("v-badge", {
                              staticClass: "justify-start",
                              attrs: {
                                color: "accent",
                                content: _vm.assignmentsList.length,
                                inline: "",
                                bordered: ""
                              }
                            })
                          : _vm._e(),
                        _c("v-spacer"),
                        !_vm.isExported
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "accent",
                                  disabled:
                                    !_vm.valid && _vm.assignmentsList.length > 0
                                },
                                on: { click: _vm.addAssignment }
                              },
                              [_vm._v("Add")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        staticClass: "pt-3 assignments-form",
                        model: {
                          value: _vm.valid,
                          callback: function($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid"
                        }
                      },
                      _vm._l(props.items, function(item, index) {
                        return _c(
                          "v-row",
                          {
                            key: index,
                            staticClass: "px-3 border-bottom",
                            attrs: { dense: "" }
                          },
                          [
                            _vm.isAdminAssignments && !_vm.selectedLocation
                              ? _c(
                                  "v-col",
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.allLocationsList,
                                        "item-text": "description",
                                        "item-value": "id",
                                        placeholder: "Location",
                                        clearable: "",
                                        rules: [_vm.rules.required],
                                        dense: ""
                                      },
                                      model: {
                                        value: item.locationId,
                                        callback: function($$v) {
                                          _vm.$set(item, "locationId", $$v)
                                        },
                                        expression: "item.locationId"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isAdminAssignments && item.isNewEntry
                              ? _c(
                                  "v-col",
                                  [
                                    _c("v-switch", {
                                      staticClass: "ma-0",
                                      attrs: {
                                        color: "accent",
                                        disabled: _vm.isExported,
                                        label: item.isTerminated
                                          ? "All"
                                          : "Active"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.checkEmpStatus(
                                            item.isTerminated
                                              ? "Terminated"
                                              : "Active"
                                          )
                                        }
                                      },
                                      model: {
                                        value: item.isTerminated,
                                        callback: function($$v) {
                                          _vm.$set(item, "isTerminated", $$v)
                                        },
                                        expression: "item.isTerminated"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : !_vm.isAdminAssignments
                              ? _c(
                                  "v-col",
                                  [
                                    _c("v-switch", {
                                      staticClass: "ma-0",
                                      attrs: {
                                        color: "accent",
                                        disabled: "",
                                        "input-value": _vm.getCurrentEmpStatus(
                                          item
                                        ),
                                        label: _vm.getCurrentEmpStatus(item)
                                          ? "All"
                                          : "Active"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.checkEmpStatus(
                                            item.isTerminated
                                              ? "Terminated"
                                              : "Active"
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.isNewEntry &&
                            !_vm.isAdminAssignments &&
                            item.isNewRow
                              ? _c(
                                  "v-col",
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        disabled:
                                          (_vm.isAdminAssignments &&
                                            !item.locationId) ||
                                          _vm.isExported,
                                        items: _vm.getNewEmployees,
                                        "item-status": "status",
                                        "item-text": "name",
                                        "item-value": "id",
                                        placeholder: "New",
                                        clearable: "",
                                        dense: "",
                                        rules: [_vm.rules.required]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.getUserOrders(item.userId)
                                        }
                                      },
                                      model: {
                                        value: item.userId,
                                        callback: function($$v) {
                                          _vm.$set(item, "userId", $$v)
                                        },
                                        expression: "item.userId"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "v-col",
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        disabled:
                                          (_vm.isAdminAssignments &&
                                            !item.locationId) ||
                                          _vm.isExported,
                                        items: _vm.getEmployees(item),
                                        "item-text": "name",
                                        "item-value": "id",
                                        placeholder: "Employee",
                                        clearable: "",
                                        dense: "",
                                        rules: [_vm.rules.required]
                                      },
                                      model: {
                                        value: item.userId,
                                        callback: function($$v) {
                                          _vm.$set(item, "userId", $$v)
                                        },
                                        expression: "item.userId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                            _c(
                              "v-col",
                              [
                                _c("v-select", {
                                  attrs: {
                                    disabled:
                                      (_vm.isAdminAssignments &&
                                        !item.locationId) ||
                                      _vm.isExported,
                                    items: _vm.getJobCodes(item),
                                    "item-text": "description",
                                    "item-value": "id",
                                    placeholder: "Job",
                                    clearable: "",
                                    rules: [_vm.rules.required],
                                    dense: ""
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.changeJobCode(item)
                                    }
                                  },
                                  model: {
                                    value: item.jobCodeId,
                                    callback: function($$v) {
                                      _vm.$set(item, "jobCodeId", $$v)
                                    },
                                    expression: "item.jobCodeId"
                                  }
                                })
                              ],
                              1
                            ),
                            !_vm.isAdminAssignments
                              ? _c(
                                  "v-col",
                                  [
                                    _c("v-switch", {
                                      staticClass: "ma-0",
                                      attrs: {
                                        color: "accent",
                                        disabled: _vm.isExported,
                                        label: item.isLead ? "Lead" : "Not Lead"
                                      },
                                      model: {
                                        value: item.isLead,
                                        callback: function($$v) {
                                          _vm.$set(item, "isLead", $$v)
                                        },
                                        expression: "item.isLead"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isAdminAssignments
                              ? _c(
                                  "v-col",
                                  [
                                    _c("v-select", {
                                      ref: "drivers",
                                      refInFor: true,
                                      attrs: {
                                        items: _vm.drivers,
                                        disabled: _vm.isExported,
                                        placeholder: "Not Driving",
                                        clearable: "",
                                        dense: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.checkIsOwnCar($event, item)
                                        }
                                      },
                                      model: {
                                        value: item.driver,
                                        callback: function($$v) {
                                          _vm.$set(item, "driver", $$v)
                                        },
                                        expression: "item.driver"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isAdminAssignments
                              ? _c(
                                  "v-col",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "date",
                                        label: "Date",
                                        dense: "",
                                        rules: [_vm.rules.required]
                                      },
                                      model: {
                                        value: item.date,
                                        callback: function($$v) {
                                          _vm.$set(item, "date", $$v)
                                        },
                                        expression: "item.date"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-col",
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    pattern:
                                      "^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$",
                                    type: "time",
                                    disabled: _vm.isExported,
                                    label: "Start",
                                    dense: "",
                                    rules: [
                                      _vm.rules.required,
                                      _vm.beforeEnd(
                                        item.startTime,
                                        item.endTime
                                      ),
                                      _vm.noOverlap(
                                        item.userId,
                                        item.startTime,
                                        item.date,
                                        "start"
                                      )
                                    ]
                                  },
                                  model: {
                                    value: item.startTime,
                                    callback: function($$v) {
                                      _vm.$set(item, "startTime", $$v)
                                    },
                                    expression: "item.startTime"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    pattern:
                                      "^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$",
                                    type: "time",
                                    disabled: _vm.isExported,
                                    label: "End",
                                    dense: "",
                                    rules: [
                                      _vm.rules.required,
                                      _vm.afterStart(
                                        item.endTime,
                                        item.startTime
                                      ),
                                      _vm.noOverlap(
                                        item.userId,
                                        item.endTime,
                                        item.date,
                                        "end"
                                      )
                                    ]
                                  },
                                  model: {
                                    value: item.endTime,
                                    callback: function($$v) {
                                      _vm.$set(item, "endTime", $$v)
                                    },
                                    expression: "item.endTime"
                                  }
                                })
                              ],
                              1
                            ),
                            !_vm.isExported
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      large: "",
                                      icon: "",
                                      color: "accent"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeAssignment(index)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-close-circle-outline")
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ]
                }
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { color: "primary", dense: "" } },
                      [
                        _c("v-spacer"),
                        !_vm.isExported
                          ? _c(
                              "v-btn",
                              {
                                class: {
                                  accent: _vm.valid
                                },
                                attrs: {
                                  color: "accent",
                                  disabled:
                                    !_vm.valid && _vm.assignmentsList.length
                                },
                                on: { click: _vm.putAssignments }
                              },
                              [_vm._v("Save")]
                            )
                          : _vm._e(),
                        _vm.calledFrom == "Grid"
                          ? _c(
                              "v-btn",
                              {
                                class: {
                                  accent: _vm.valid,
                                  cancel: _vm.cancel
                                },
                                attrs: { color: "accent" },
                                on: { click: _vm.cancel }
                              },
                              [_vm._v("Cancel")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }