var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { dense: "", dark: "", color: "primary" } },
        [_c("Breadcrumbs", { attrs: { customLastCrumb: _vm.order.name } })],
        1
      ),
      _c("v-divider"),
      _vm.isExported
        ? _c(
            "v-row",
            { staticClass: "justify-center ma-5" },
            [
              _c(
                "v-card",
                { staticClass: "headline accent pa-2", attrs: { flat: "" } },
                [
                  _c("v-icon", [_vm._v("mdi-information-outline")]),
                  _vm._v(" Payroll has been submitted. Order is readonly ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-expansion-panels",
            {
              staticClass: "ma-5",
              attrs: { tile: "" },
              model: {
                value: _vm.expandPanel,
                callback: function($$v) {
                  _vm.expandPanel = $$v
                },
                expression: "expandPanel"
              }
            },
            [
              _c(
                "v-expansion-panel",
                {
                  on: {
                    change: function($event) {
                      _vm.showDetails = !_vm.showDetails
                    }
                  }
                },
                [
                  _c(
                    "v-expansion-panel-header",
                    { staticClass: "primary" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "4" } },
                        [
                          _c("CancelIcon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.order.isDeleted,
                                expression: "order.isDeleted"
                              }
                            ],
                            staticClass: "vertical-align-middle"
                          }),
                          _c("QuoteIcon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.order.type === _vm.quote,
                                expression: "order.type === quote"
                              }
                            ],
                            staticClass: "vertical-align-middle"
                          }),
                          _c("WeddingIcon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.order.format === _vm.wedding,
                                expression: "order.format === wedding"
                              }
                            ],
                            staticClass: "vertical-align-middle"
                          }),
                          _c("EzCaterIcon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.order.ezCaterOrderId,
                                expression: "order.ezCaterOrderId"
                              }
                            ],
                            staticClass: "vertical-align-middle"
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "headline",
                              attrs: {
                                target: "_blank",
                                href: _vm.getMediaMonkeyOrderLink(
                                  _vm.order.cityOrderId
                                )
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.order.name) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "ma-1" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isOrderModified,
                              expression: "isOrderModified"
                            }
                          ],
                          class: {
                            accent: _vm.valid
                          },
                          attrs: { color: "accent" },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showDetails,
                          expression: "!showDetails"
                        }
                      ],
                      staticClass: "ma-1"
                    },
                    _vm._l(_vm.listItems, function(item, index) {
                      return _c(
                        "v-col",
                        {
                          key: index,
                          attrs: { cols: "12", md: "6", lg: "3", xl: "3" }
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(" " + _vm._s(item.text) + " ")]
                          ),
                          item.isStatic
                            ? _c("v-list-item-content", [
                                _vm._v(" " + _vm._s(item.value) + " ")
                              ])
                            : _vm._e(),
                          item.isEndTime
                            ? _c(
                                "v-list-item-content",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      pattern:
                                        "^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$",
                                      type: "time",
                                      rows: "1",
                                      disabled: _vm.isExported,
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.orderEndTime,
                                      callback: function($$v) {
                                        _vm.orderEndTime = $$v
                                      },
                                      expression: "orderEndTime"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          item.isNotes
                            ? _c(
                                "v-list-item-content",
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      rows: "1",
                                      outlined: "",
                                      disabled: _vm.isExported,
                                      "auto-grow": ""
                                    },
                                    model: {
                                      value: _vm.orderNotes,
                                      callback: function($$v) {
                                        _vm.orderNotes = $$v
                                      },
                                      expression: "orderNotes"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          item.isLink
                            ? _c("v-list-item-content", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: item.link, target: "_blank" }
                                  },
                                  [
                                    _vm._v(_vm._s(item.value) + " "),
                                    _c("v-icon", [_vm._v("mdi-open-in-new")])
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          item.printLink && item.method == "PrepChit"
                            ? _c("v-list-item-content", [
                                _c(
                                  "a",
                                  {
                                    attrs: { target: "_blank" },
                                    on: {
                                      click: function($event) {
                                        return _vm.cateringChipPrint(
                                          _vm.order.cityOrderId,
                                          _vm.prepChitProcessId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(_vm._s(item.value) + " "),
                                    _c("v-icon", [_vm._v("mdi-open-in-new")])
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          item.printLink && item.method == "AssemblyChit"
                            ? _c("v-list-item-content", [
                                _c(
                                  "a",
                                  {
                                    attrs: { target: "_blank" },
                                    on: {
                                      click: function($event) {
                                        return _vm.cateringChipPrint(
                                          _vm.order.cityOrderId,
                                          _vm.assemblyProcesId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(_vm._s(item.value) + " "),
                                    _c("v-icon", [_vm._v("mdi-open-in-new")])
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isOrderModified,
                              expression: "isOrderModified"
                            }
                          ],
                          class: {
                            accent: _vm.valid
                          },
                          attrs: { color: "accent" },
                          on: { click: _vm.save }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-1" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isExported,
                                  expression: "!isExported"
                                }
                              ],
                              class: {
                                accent: _vm.valid
                              },
                              attrs: { color: "accent" },
                              on: { click: _vm.modify }
                            },
                            [_vm._v("Modify")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-1" },
                        [
                          _c("OrderDetailsTriColumn", {
                            attrs: { order: _vm.order }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.mounted
                ? _c("AddContent", { attrs: { order: _vm.order.cityOrderId } })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("Assignments", {
                attrs: {
                  isExported: _vm.isExported,
                  order: _vm.order,
                  isAdminAssignments: false,
                  calledFrom: "Details"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTipsAssignment,
                  expression: "showTipsAssignment"
                }
              ],
              attrs: { cols: "12" }
            },
            [
              _vm.mounted
                ? _c("OrderTipsAssignment", {
                    key: _vm.modals,
                    attrs: { order: _vm.order, isExported: _vm.isExported },
                    on: {
                      TipsValidation: function($event) {
                        return _vm.displayValidationAlert("tipsAssignment")
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-dialog",
            {
              attrs: { "overlay-opacity": "0", "max-width": "300px" },
              model: {
                value: _vm.validationAlert,
                callback: function($$v) {
                  _vm.validationAlert = $$v
                },
                expression: "validationAlert"
              }
            },
            [
              _c("v-alert", { attrs: { type: "error" } }, [
                _vm._v(_vm._s(_vm.alert))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }