<template>
  <div>
    <v-toolbar dense dark color="primary">
      <Breadcrumbs :customLastCrumb="order.name" />
    </v-toolbar>
    <v-divider></v-divider>
    <v-row class="justify-center ma-5" v-if="isExported">
      <v-card class="headline accent pa-2" flat>
        <v-icon>mdi-information-outline</v-icon>
        Payroll has been submitted. Order is readonly
      </v-card>
    </v-row>
    <v-row>
      <v-expansion-panels class="ma-5" v-model="expandPanel" tile>
        <v-expansion-panel @change="showDetails = !showDetails">
          <v-expansion-panel-header class="primary">
            <v-col cols="4" class="pa-0">
              <CancelIcon
              v-show="order.isDeleted"
              class="vertical-align-middle"
              />
              <QuoteIcon
                v-show="order.type === quote"
                class="vertical-align-middle"
              />
              <WeddingIcon
                v-show="order.format === wedding"
                class="vertical-align-middle"
              />
              <EzCaterIcon
                v-show="order.ezCaterOrderId"
                class="vertical-align-middle"
              />
              <a
                target="_blank"
                class="headline"
                :href="getMediaMonkeyOrderLink(order.cityOrderId)"
              >
                {{ order.name }}
              </a>
            </v-col>
          </v-expansion-panel-header>
          <v-row class="ma-1">
            <v-spacer></v-spacer>
            <v-btn
              v-show="isOrderModified"
              color="accent"
              @click="cancel"
              :class="{
                accent: valid,
              }"
              >Cancel</v-btn
            >
          </v-row>
          <v-row class="ma-1" v-show="!showDetails">
            <v-col
              v-for="(item, index) in listItems"
              :key="index"
              cols="12"
              md="6"
              lg="3"
              xl="3"
            >
              <v-list-item-title style="font-weight: bold">
                {{ item.text }}
              </v-list-item-title>
              <v-list-item-content v-if="item.isStatic">
                {{ item.value }}
              </v-list-item-content>
              <v-list-item-content v-if="item.isEndTime">
                <v-text-field
                  pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                  type="time"
                  v-model="orderEndTime"
                  rows="1"
                  :disabled="isExported"
                  outlined
                >
                </v-text-field>
              </v-list-item-content>
              <v-list-item-content v-if="item.isNotes">
                <v-textarea
                  v-model="orderNotes"
                  rows="1"
                  outlined
                  :disabled="isExported"
                  auto-grow
                ></v-textarea>
              </v-list-item-content>
              <v-list-item-content
              v-if="item.isLink">
                <a
                  :href="item.link"
                  target="_blank"
                  >{{ item.value }}
                  <v-icon>mdi-open-in-new</v-icon></a
                >
              </v-list-item-content>
              <v-list-item-content
                v-if="item.printLink && item.method=='PrepChit'">
                <a
                  @click="cateringChipPrint(order.cityOrderId,
                   prepChitProcessId)"
                  target="_blank"
                  >{{ item.value }}
                  <v-icon>mdi-open-in-new</v-icon></a
                >
              </v-list-item-content>
              <v-list-item-content
                v-if="item.printLink && item.method=='AssemblyChit'">
                <a
                  @click="cateringChipPrint(order.cityOrderId,
                  assemblyProcesId)"
                  target="_blank"
                  >{{ item.value }}
                  <v-icon>mdi-open-in-new</v-icon></a
                >
              </v-list-item-content>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-show="isOrderModified"
              color="accent"
              @click="save"
              :class="{
                accent: valid,
              }"
              >Save</v-btn
            >
          </v-card-actions>
          <v-expansion-panel-content>
            <v-row class="ma-1">
              <v-spacer></v-spacer>
              <v-btn
                color="accent"
                @click="modify"
                v-show="!isExported"
                :class="{
                  accent: valid,
                }"
                >Modify</v-btn
              >
            </v-row>
            <v-row class="ma-1">
              <OrderDetailsTriColumn :order="order" />
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row>
      <v-col cols="12">
        <AddContent :order="order.cityOrderId" v-if="mounted"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Assignments :isExported="isExported"
        :order="order" :isAdminAssignments="false" :calledFrom="'Details'" />
      </v-col>
      <v-col cols="12" v-show="showTipsAssignment">
        <OrderTipsAssignment
          :order="order"
          v-if="mounted"
          @TipsValidation="displayValidationAlert(`tipsAssignment`)"
          :key="modals"
          :isExported="isExported"
        ></OrderTipsAssignment>
      </v-col>
    </v-row>
    <div>
      <v-dialog overlay-opacity="0" max-width="300px" v-model="validationAlert">
        <v-alert type="error">{{ alert }}</v-alert>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import orderApi from "@/api/orderApi";
import OrderTipsAssignment from "@/components/OrderTipsAssignment.vue";
import Assignments from "@/components/Assignments.vue";
import AddContent from "@/components/AddContent.vue";
import OrderDetailsTriColumn from "@/components/OrderDetailsTriColumn.vue";
import {currentUserMixin} from "@/mixins/currentUserMixin.js";
import {iconMixin} from "@/mixins/iconMixin.js";
import UserTime from "@/helpers/userTime.js";
import moment from "moment/moment";
import {mapGetters} from "vuex";
import numeral from "numeral";
import Vue from "vue";

export default {
  mixins: [currentUserMixin, iconMixin],
  name: "OrderDetails",
  components: {
    OrderTipsAssignment,
    Assignments,
    Breadcrumbs,
    OrderDetailsTriColumn,
    AddContent,
  },
  computed: {
    ...mapGetters({workingOrderId: "getWorkingOrderId"}),
    listItems() {
      return [
        {
          text: "Event Date",
          value: UserTime.getLongDate(this.order.startDateTime),
          isStatic: true,
        },
        {
          text: "Eat Time",
          value: this.getStartTime(this.order),
          isStatic: true,
        },
        {
          text: "MM Invoice",
          value: `Invoice - ${this.order.cityOrderId}`,
          link: this.getMediaMonkeyInvoiceLink(this.order.cityOrderId),
          isLink: true,
        },
        {
          text: "MM Production Sheet",
          value: `Sheet - ${this.order.cityOrderId}`,
          link: this.getMediaMonkeyProductionSheetLink(this.order.cityOrderId),
          isLink: true,
        },
        {
          text: "MM Prep Catering Chit Print",
          value: `Catering Chit - ${this.order.cityOrderId}`,
          method: "PrepChit",
          printLink: true,
        },
        {
          text: "MM Assembly Catering Chit Print",
          value: `Catering Chit - ${this.order.cityOrderId}`,
          method: "AssemblyChit",
          printLink: true,
        },
        {
          text: "Instructions",
          value: this.order.eventInstructions,
          isStatic: true,
        },
        {
          text: "End Time",
          value: this.getEndTime(this.order),
          isEndTime: true,
        },
        {
          text: "Additional Notes",
          value: this.orderNotes,
          isNotes: true,
        },
      ];
    },
  },
  data() {
    return {
      order: {},
      mounted: false,
      modals: 0,
      orderOriginalValue: {},
      orderEndTime: "",
      endTimeDisplay: "",
      timePicker: false,
      showDetails: false,
      unassigned: "Unassigned",
      showTipsAssignment: true,
      dateTimeMaxValue: "9999-12-31T23:59:59.9999999",
      expandPanel: 1,
      isOrderModified: false,
      isLoading: false,
      orderNotes: "",
      isExported: false,
      prepChitProcessId: "cf68d857-f5c0-4918-8446-fc8e4fdd04ca",
      assemblyProcesId: "4a96a289-e020-4a78-b213-d7b085120429",
    };
  },
  mounted() {
    this.getOrderDetails();
  },
  methods: {
    getNumeral(amount) {
      return numeral(amount).format("$0,0.00");
    },
    getStartTime(order) {
      return order.startDateTime ?
        `${UserTime.getShortTime(order.startDateTime)} ${
          order.startDateTimeZone
        }` :
        "";
    },
    getEndTime(order) {
      return order.startDateTime === order.expectedEnd ||
        !order.expectedEnd ||
        order.expectedEnd === this.dateTimeMaxValue ?
        UserTime.NoTimeSet :
        `${UserTime.getShortTime(order.expectedEnd)} ${
          order.startDateTimeZone
        }`;
    },
    getMediaMonkeyOrderLink(cityOrderId) {
      return process.env.VUE_APP_ORDER_DETAILS_MONKEY_MEDIA_LINK + cityOrderId;
    },
    getMediaMonkeyProductionSheetLink(cityOrderId) {
      return (
        process.env.VUE_APP_ORDER_DETAILS_MONKEY_MEDIA_PRODUCTION_SHEET_LINK +
        cityOrderId + "/?shownav=0"
      );
    },
    getMediaMonkeyInvoiceLink(cityOrderId) {
      return (
        process.env.VUE_APP_ORDER_DETAILS_MONKEY_MEDIA_INVOICE_LINK +
        cityOrderId
      );
    },
    cateringChipPrint(cityOrderId, processId) {
      const store = this.order.market.split("/")[1];
      Vue.swal({
        icon: "warning",
        // eslint-disable-next-line max-len
        title: `Are you sure you want to print the chit for ${store} ?`,
        text: "",
        type: "success",
        showCancelButton: true,
        confirmButtonColor: "#AF1E2D",
        confirmButtonText: "Print",
        focusCancel: true,
        closeOnConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // eslint-disable-next-line max-len
          orderApi.chitRePrint(cityOrderId, processId).then((response) => {
            if (response.status == 200) {
              this.$root.$emit("successToast", "Printing job queued.");
            } else {
              // eslint-disable-next-line max-len
              this.$root.$emit("erroToast", "Erro encountered while Printing the chit.");
            }
          }),
          (error) => {
            this.$root.$emit(
                "errorToast",
                "Error encountered.  Please contact support.",
            );
          };
        }
      });
    },
    async getOrderDetails() {
      this.isLoading = true;
      await orderApi.getOrderById(this.workingOrderId).then((response) => {
        if (response.data) {
          this.order = response.data;
          this.orderOriginalValue = this.order;
          this.mounted = true;
          this.endTimeDisplay = this.getEndTime(this.order);
          this.orderEndTime =
            this.endTimeDisplay === UserTime.NoTimeSet ?
              "" :
              UserTime.get24HourTime(this.order.expectedEnd);
          this.orderNotes = this.order.notes;
        }
      });
      this.isLoading = false;
      if (this.order.tips) {
        this.order.tips.forEach((x) => {
          x.payrollSubmissionId ?
            this.isExported = true : this.isExported = false;
        });
      }
    },
    updateOrder() {
      orderApi.putOrder(this.order).then((response) => {
        this.$root.$emit("successToast", "Order updated.");
      }),
      (error) => {
        this.$root.$emit(
            "errorToast",
            "Error encountered.  Please contact support.",
        );
      };
    },
    refresh() {
      this.modals++;
    },
    cancel() {
      this.getOrderDetails();
      this.isOrderModified = false;
    },
    modify() {
      this.expandPanel = 1;
      this.showDetails = !this.showDetails;
    },
    save() {
      if (this.validation(this.order)) {
        this.editing = false;
        this.updateOrder();
        this.isOrderModified = false;
      } else {
        this.displayValidationAlert("orderDetails");
      }
    },
    validation(order) {
      const orderStart = moment.utc(order.startDateTime);
      const orderEnd = moment.utc(order.expectedEnd);
      return orderStart.isSameOrBefore(orderEnd);
    },
    displayValidationAlert(text) {
      let alert = "";
      if (text === "employeeAssignment") {
        alert = "Please verify employee(s) start and end times";
      }
      if (text === "tipsAssignment") {
        alert = "Tips dispersed is greater than total tip amount";
      }
      if (text === "orderDetails") {
        alert = "Event End Time is before Start Time";
      }
      this.$swal({
        text: alert,
        icon: "error",
      });
    },
    getDateTimeFromDateAndTime(date, time) {
      return moment.utc(`${date} ${time}`);
    },
  },
  watch: {
    orderEndTime: {
      handler: function(val, oldVal) {
        if (!this.isLoading) {
          this.isOrderModified = true;
          if (this.orderEndTime != "") {
            this.order.expectedEnd = this.getDateTimeFromDateAndTime(
                UserTime.getShortDate(this.order.startDateTime),
                this.orderEndTime,
            );
          }
          this.endTimeDisplay = this.getEndTime(this.order);
        }
      },
    },
    orderNotes: {
      handler: function(val, oldVal) {
        if (!this.isLoading) {
          this.isOrderModified = true;
          this.order.notes = this.orderNotes;
        }
      },
    },
  },
};
</script>

<style>
a:link {
  text-decoration: none;
}
.v-application a:hover {
  color: #af1e2d;
}
</style>
