<template>
    <v-container fluid class="pa-0">
      <v-layout flex align-center justify-center>
        <v-flex md12>
          <v-card tile>
            <v-toolbar dense dark color="primary">
              <Breadcrumbs :customLastCrumb="'User Search'" />
            </v-toolbar>
            <v-divider></v-divider>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="profile-parent-div">
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                width="40%"
                v-model="search"
                v-on:change="searchValue"
                append-icon="mdi-magnify"
                label="Search by email or Employee Id"
                single-line
                hide-details
                class="pa-3">
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn
                small
                @click="getUserProfileDetails"
                :disabled=checkEnable
                color="accent"
                class="ma-3">
                Submit
              </v-btn>
            </v-col>
            <v-col cols="1">
              <v-btn
                small
                @click="clearContent"
                :disabled=checkEnable
                color="accent"
                class="ma-3">
                Clear
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <UserDetails :user-data="userData" :visible="visible"></UserDetails>
          </v-row>
        </v-card-text>
      </div>
      <div>
      <v-dialog :value="show" persistent>
        <v-container class="modalPopup">
          <v-row>
            <v-col cols="12">
             {{ search }} user not found.
            </v-col>
          </v-row>
          <v-btn
            small
            @click="closeModal"
            color="accent"
            class="ma-3">
            Close
          </v-btn>
        </v-container>
      </v-dialog>
    </div>
    </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import UserDetails from "@/components/UserDetails";
import userApi from "@/api/userApi";
export default {
  name: "UserSearch",
  components: {
    Breadcrumbs,
    UserDetails,
  },
  data() {
    return {
      search: "",
      userData: {},
      visible: false,
      isEnable: true,
      userNotFound: false,
    };
  },
  mounted() {},
  methods: {
    async getUserProfileDetails() {
      const email = this.search;
      await userApi.getProfileDetails(email).then((response) => {
        if (response.data) {
          if (parseInt(response.data.cityEmployeeId) == 0) {
            this.userNotFound = true;
          } else {
            this.userData = response.data;
            this.visible = true;
            this.userNotFound = false;
          }
        }
      });
    },
    searchValue() {
      if (this.search.length > 0) {
        this.isEnable = false;
      }
    },
    closeModal() {
      this.userNotFound = false;
    },
    clearContent() {
      this.search = "";
      this.visible = false;
      this.isEnable = true;
    },
  },
  computed: {
    checkEnable() {
      return this.isEnable;
    },
    show: {
      get() {
        return this.userNotFound;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>
<style scoped>
  .row {
    justify-content: flex-start !important;
  }
  ul {
    list-style-type: square;
  }
  .profile-parent-div li {
    margin: 2px 0;
  }
  .profile-parent-div {
    background-color: #ffffff;
  }
  .profile-parent-div div {
    font-size: 16px;
    color: #000000DE
  }
  .modalPopup {
    background-color: #f5f5f5;
    text-align: center
  }
</style>
