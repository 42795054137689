var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    _vm._l(_vm.detailItems, function(item, index) {
      return _c(
        "v-list-item",
        { key: index },
        [
          _c(
            "v-list-item-content",
            { staticClass: "py-0" },
            [
              item.tooltip
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-list-item-title",
                                  _vm._g(
                                    {
                                      staticClass: "font-weight-bold mt-1",
                                      domProps: {
                                        textContent: _vm._s(item.label)
                                      }
                                    },
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v(_vm._s(item.tooltip))])]
                  )
                : _c("v-list-item-title", {
                    staticClass: "font-weight-bold mt-1",
                    domProps: { textContent: _vm._s(item.label) }
                  }),
              !item.isLink
                ? _c(
                    "v-list-item-content",
                    { staticClass: "pt-0 pb-0 body-2" },
                    [
                      _c(
                        "div",
                        [
                          _c("CancelIcon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.voided,
                                expression: "item.voided"
                              }
                            ],
                            staticClass: "vertical-align-middle"
                          }),
                          _c("EzCaterIcon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.ezOrder,
                                expression: "item.ezOrder"
                              }
                            ],
                            staticClass: "vertical-align-middle"
                          }),
                          _c("QuoteIcon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.quote === _vm.quote,
                                expression: "item.quote === quote"
                              }
                            ],
                            staticClass: "vertical-align-middle"
                          }),
                          _c("WeddingIcon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.format && item.format === _vm.wedding,
                                expression:
                                  "item.format && item.format === wedding"
                              }
                            ],
                            staticClass: "vertical-align-middle"
                          }),
                          _vm._v(" " + _vm._s(item.data))
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              item.isLink
                ? _c(
                    "v-list-item-content",
                    { staticClass: "pt-0 pb-0 body-2" },
                    [
                      _c(
                        "a",
                        { attrs: { href: item.link, target: "_blank" } },
                        [
                          _vm._v(_vm._s(item.data) + " "),
                          _c("v-icon", [_vm._v("mdi-open-in-new")])
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }