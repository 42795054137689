<template>
  <v-container fluid class="pa-0">
    <v-layout flex align-center justify-center>
      <v-flex md12>
        <v-toolbar dense color="primary">
          <Breadcrumbs/>
        </v-toolbar>
        <v-divider></v-divider>
        <OrdersFilterToolbar
          class="ma-1"
          v-show="!isSubmissionsHistory"
          :allOrders="currentOrders()"
          :isPayroll="true"
          :orderMarketList="marketList"
          @setFilteredOrders="setFilteredOrders"
          @tipDiscrepancy="tipDiscrepancySwitch"
        >
        </OrdersFilterToolbar>
        <v-row>
          <v-container fluid class="ma-1 data-container">
            <v-data-table
              v-if="isSubmissionsHistory"
              :headers="submissionsHistoryHeaders"
              :items="payrollSubmissions"
              no-data-text="No Orders Found"
            >
              <template v-slot:top>
                <v-row>
                  <v-switch
                    class="ma-1 ml-7"
                    v-model="isSubmissionsHistory"
                    v-on="on"
                    label="History"
                    color="accent"
                  ></v-switch>
                </v-row>
              </template>
              <template #item.timestamp="{item}">
                {{ getDateFromDateTime(item.timestamp) }}
              </template>
              <template #item.processedDate="{item}">
                {{ getCalendarTime(item.processedDate) }}
              </template>
              <template #item.amount="{item}">
                {{ getNumeral(item.amount) }}
              </template>
            </v-data-table>
            <v-data-table
              v-if="!isSubmissionsHistory"
              :headers="headers"
              :items="filteredOrders"
              :loading="isLoading"
              show-expand
              loading-text="Loading Orders"
              no-data-text="No Orders Found"
              :sort-by="sortBy"
              :sort-desc="sortOrder"
            >
            <template v-slot:header.cityOrderId>
              <button @click="filterClick('cityOrderId')">Order</button>
            </template>
            <template v-slot:header.name>
              <button @click="filterClick('name')">Name</button>
            </template>
            <template v-slot:header.market>
              <button @click="filterClick('market')">Market</button>
            </template>
            <template v-slot:header.startDateTime>
              <button @click="filterClick('startDateTime')">Event Date</button>
            </template>
            <template v-slot:header.paidDate>
              <button @click="filterClick('paidDate')">Date Paid</button>
            </template>
            <template v-slot:header.driverBonus>
              <button @click="filterClick('driverBonus')">
                Total Driver Bonus</button>
            </template>
            <template v-slot:header.gratuity>
              <button @click="filterClick('gratuity')">
                Total Gratuity</button>
            </template>
            <template v-slot:header.tips>
              <button @click="filterClick('tips')">Total Disbursed</button>
            </template>
              <template v-slot:top>
                <v-row>
                  <v-switch
                    v-model="isSubmissionsHistory"
                    class="ma-1 ml-7"
                    v-on="on"
                    label="History"
                    color="accent"
                  ></v-switch>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="accent"
                    :disabled="!filteredOrders.length"
                    @click="exportToExcel"
                    :class="['exportexcel',{'accent': valid }]"
                    ><i class="fas fa-download"></i></v-btn
                  >
                  <v-dialog v-model="exportDialog" max-width="330px" persistent>
                    <template v-slot:activator="{ on }" persistent>
                      <v-btn
                        v-show="!isManager"
                        class="mr-3"
                        v-on="on"
                        color="accent"
                        :disabled="tipSwitch || !filteredOrders.length"
                        >Post To Payroll</v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title>Please Enter Paycheck Date</v-card-title>
                      <v-card-text>
                        <v-menu
                          v-model="exportMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              class="mt-2"
                              v-model="payrollDate"
                              label="Date"
                              prepend-icon="event"
                              readonly
                              clearable
                              v-on="on"
                              :rules="[rules.required, validDate(payrollDate)]"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="payrollDate"
                            @input="exportMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-3"
                          v-on="on"
                          color="accent"
                          @click="exportDialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          class="mr-3"
                          v-on="on"
                          color="accent"
                          :disabled="!isDateValid"
                          @click="submitPayrollData"
                          >Submit</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </template>
              <template #item.cityOrderId="{item}">
                <a @click="routeToOrderDetails(item.cityOrderId)">
                  {{ item.cityOrderId }}
                  <v-icon>mdi-open-in-new</v-icon>
                </a>
              </template>
              <template #item.gratuity="{item}">
                {{ getNumeral(item.gratuity + item.autoGratuity) }}
              </template>
              <template #item.startDateTime="{item}">
                {{ getDateFromDateTime(item.startDateTime) }}
              </template>
              <template #item.paidDate="{item}">
                {{ getDateFromDateTime(item.paidDate) }}
              </template>
              <template #item.driverBonus="{item}">
                {{ getNumeral(calculateTotalDriverBonus(item)) }}
              </template>
              <template #item.tips="{item}">
                {{ getNumeral(calculateTotalDispersedTips(item.tips)) }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-data-table
                    class="tipDisbursed"
                    :headers="tipsHeaders"
                    hide-default-footer
                    :items="item.tips"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Disbursed Tips</v-toolbar-title>
                      </v-toolbar>
                      <v-divider class="primary"></v-divider>
                    </template>
                    <template #item.name="{item}">
                      {{ `${item.userFirstName} ${item.userLastName}` }}
                    </template>
                    <template #item.gratuity="{item}">
                      {{ getNumeral(item.gratuity) }}
                    </template>
                    <template #item.autoGratuity="{item}">
                      {{ getNumeral(item.autoGratuity) }}
                    </template>
                    <template #item.driverBonus="{item}">
                      {{ getNumeral(item.driverBonus) }}
                    </template>
                    <template #item.total="{item}">
                      {{ getNumeral(item.gratuity + item.autoGratuity) }}
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-container>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import payrollApi from "@/api/payrollApi";
import UserTime from "@/helpers/userTime";
import numeral from "numeral";
import OrdersFilterToolbar from "@/components/OrdersFilterToolbar";
import moment from "moment";
import {mapGetters} from "vuex";
import {Role} from "@/helpers/role";
import router from "@/router/index.js";
import * as XLSX from "xlsx";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default {
  name: "PayrollTips",
  components: {
    Breadcrumbs,
    OrdersFilterToolbar,
  },
  mounted() {
    this.updatePayrollData();
    if (this.$cookie.get("payrollSortBy")) {
      this.sortBy = this.$cookie.get("payrollSortBy");
      this.sortOrder = this.$cookie.get("payrollSortOrder");
      this.sortOrder = JSON.parse(this.sortOrder);
    }
  },
  data() {
    return {
      filteredOrders: [],
      marketList: [],
      validOrders: [],
      invalidOrders: [],
      tipSwitch: false,
      payrollDate: "",
      isDateValid: false,
      exportMenu: false,
      exportDialog: false,
      ordersWithoutTips: [],
      rules: {
        required: (value) => !!value || "Required.",
      },
      payrollSubmissions: [],
      isManager: false,
      isPayroll: false,
      isLoading: false,
      isSubmissionsHistory: false,
      sortOrder: false,
      sortBy: "startDateTime",
    };
  },
  computed: {
    tipsHeaders() {
      return [
        {text: "Name", value: "name"},
        {text: "Id", value: "etlCityEmployeeId"},
        {text: "Gratuity", value: "gratuity"},
        {text: "Service Charge", value: "autoGratuity"},
        {text: "Driver Bonus", value: "driverBonus"},
        {text: "Total", value: "total"},
        {text: "Catering Manager", value: "cateringManagerName"},
      ];
    },
    headers() {
      return [
        {text: "Order", value: "cityOrderId"},
        {text: "Name", value: "name"},
        {text: "Market", value: "market"},
        {text: "Event Date", value: "startDateTime"},
        {text: "Date Paid", value: "paidDate"},
        {text: "Total Driver Bonus", value: "driverBonus"},
        {text: "Total Gratuity", value: "gratuity"},
        {text: "Total Disbursed", value: "tips"},
        {text: "", value: "data-table-expand"},
      ];
    },
    submissionsHistoryHeaders() {
      return [
        {text: "Batch Id", value: "id"},
        {text: "Paycheck Date", value: "timestamp"},
        {text: "Processed Date", value: "processedDate"},
        {text: "Type", value: "type"},
        {text: "Batch Total", value: "amount"},
        {text: "Processed By", value: "processedByName"},
      ];
    },
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      currentUser: "getCurrentUser",
    }),
  },
  methods: {
    updatePayrollData() {
      this.isLoading = true;
      payrollApi.getAllOrdersForPayrollTips().then((response) => {
        if (response.data) {
          this.marketList = response.data.marketList;
          this.payrollSubmissions = response.data.payrollSubmissions;
          this.separateOrders(response.data.orders);
          response.data.ordersWithoutTips.forEach((x) => {
            this.invalidOrders.push(x);
          });
        }
      });
      this.currentUser.roles.forEach((x) => {
        if (x === Role.CateringManager) {
          this.isManager = true;
        } else if (x === Role.Payroll) {
          this.isPayroll = true;
        }
      });
      this.isLoading = false;
    },
    filterClick(clickedHeader) {
      // eslint-disable-next-line max-len
      const sortBy = this.$cookie.get("payrollSortBy");
      if (clickedHeader === sortBy) {
        this.$cookie.set("payrollSortOrder", !this.sortOrder);
      } else {
        this.$cookie.set("payrollSortOrder", false);
      }
      this.$cookie.set("payrollSortBy", clickedHeader);
    },
    submitPayrollData() {
      this.exportDialog = false;
      this.isLoading = true;
      payrollApi
          .putPayrollTipsSubmissions(this.payrollDate, this.filteredOrders)
          .then((response) => {
            this.invalidOrders = [];
            this.filteredOrders = [];
            this.validOrders = [];
            this.payrollDate = "";
            this.payrollSubmissions = response.data.payrollSubmissions;
            this.separateOrders(response.data.orders);
            response.data.ordersWithoutTips.forEach((x) => {
              this.invalidOrders.push(x);
            });
            this.$root.$emit("successToast", "Payroll updated.");
          }),
      (error) => {
        this.$root.$emit(
            "errorToast",
            "Error encountered.  Please contact support.",
        );
      };
      this.isLoading = false;
    },
    separateOrders(orders) {
      orders.forEach((order) => {
        const orderTipTotal = +(order.autoGratuity +
          order.gratuity).toFixed(2);
        const totalTipsDistribution = this.calculateTotalDispersedTips(
            order.tips,
        );
        if (orderTipTotal == totalTipsDistribution) {
          this.validOrders.push(order);
        } else {
          this.invalidOrders.push(order);
        }
      });
    },
    getDateFromDateTime(date) {
      return UserTime.getShortDate(date);
    },
    getCalendarTime(date) {
      return UserTime.getCalendarTimestamp(date);
    },
    getNumeral(amount) {
      return numeral(amount).format("$0,0.00");
    },
    calculateTotalDispersedTips(tips) {
      let totalTipsDistribution = 0;
      if (tips) {
        tips.forEach((tip) => {
          totalTipsDistribution +=
            +(tip.gratuity + tip.autoGratuity).toFixed(2);
        });
      }
      return +totalTipsDistribution.toFixed(2);
    },
    calculateTotalDriverBonus(order) {
      let totalDriverBonus = 0;
      if (order.tips) {
        order.tips.forEach((tip) => {
          totalDriverBonus += tip.driverBonus;
        });
      }
      return totalDriverBonus;
    },
    setFilteredOrders(orders) {
      this.filteredOrders = orders;
    },
    tipDiscrepancySwitch(payload) {
      if (payload.tipSwitch) {
        this.tipSwitch = true;
      } else {
        this.tipSwitch = false;
      }
    },
    currentOrders() {
      if (this.tipSwitch) {
        return this.invalidOrders;
      } else {
        return this.validOrders;
      }
    },
    validDate(payrollDate) {
      if (
        moment(payrollDate).isSameOrAfter(this.getDateFromDateTime(new Date()))
      ) {
        this.isDateValid = true;
        return true;
      } else {
        this.isDateValid = false;
        return "Date must be in the future";
      }
    },
    routeToOrderDetails(orderId) {
      if (this.isPayroll) {
        router.push({
          name: "ScheduleDetails",
          params: {orderId: orderId},
        });
      } else {
        router.push({
          name: "OrderDetails",
          params: {orderId: orderId},
        });
      }
    },
    exportToExcel() {
      if (this.filteredOrders.length === 0) {
        return;
      }
      console.log("this.filteredOrders:::", this.filteredOrders);
      const headers = ["Order", "Name", "Market",
        "Event Date", "Date Paid", "Total Driver Bonus",
        "Total Gratuity", "Total Disbursed",
      ];
      // Map data to match headers
      const dataMapped = this.filteredOrders.map((item) => ({
        "Order": item.cityOrderId,
        "Name": item.name,
        "Market": item.market,
        "Event Date": this.getDateFromDateTime(item.startDateTime),
        "Date Paid": this.getDateFromDateTime(item.paidDate),
        // eslint-disable-next-line max-len
        "Total Driver Bonus": this.getNumeral(this.calculateTotalDriverBonus(item)),
        // eslint-disable-next-line max-len
        "Total Gratuity": this.getNumeral(item.gratuity + item.autoGratuity),
        // eslint-disable-next-line max-len
        "Total Disbursed": this.getNumeral(this.calculateTotalDispersedTips(item.tips)),
      }));

      // eslint-disable-next-line max-len
      const worksheet = XLSX.utils.json_to_sheet(dataMapped, {header: headers});
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      // eslint-disable-next-line max-len
      const excelBuffer = XLSX.write(workbook, {bookType: "xlsx", type: "array"});
      // eslint-disable-next-line max-len
      const blob = new Blob([excelBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PayrollTips_data.xlsx");
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.approve:hover {
  color: green;
}
.exportexcel {
  right: 25px;
  top: 10px;
}
.decline:hover {
  color: red;
}
.v-divider {
  border: solid;
}
.tipDisbursed {
  border-style: solid;
  border-color: #cec3ba;
  position: relative;
}
.data-container {
  position: relative;
}
</style>
