import Vue from "vue";

export default {
  getById(userId) {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Users/${userId}`);
  },
  getByEmail(email) {
    return Vue.axios.get(
        `${process.env.VUE_APP_API_BASE}/Users/GetUserByEmail/${email}`,
    );
  },
  getAllUsers() {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Users/`);
  },
  getProfileDetails(param) {
    // eslint-disable-next-line max-len
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Users/UserProfileDetails?userParam=${param}`);
  },
  postUserRoles(roles, email, azureAD) {
    // eslint-disable-next-line max-len
    return Vue.axios.post(`${process.env.VUE_APP_API_BASE}/Users/UpdateRoles`, [{
      roles: roles,
      email: email,
      azureAd: azureAD,
    }]);
  },
  getUserRoles(email) {
    // eslint-disable-next-line max-len
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Users/GetUserRoles?email=${email}`);
  },
  upsertNotificationFlag(email, enabled) {
    // eslint-disable-next-line max-len
    return Vue.axios.post(`${process.env.VUE_APP_API_BASE}/Users/UpsertSMSNotification`, [{
      email: email,
      enabled: enabled,
    }]);
  },
};
