<template>
  <v-row>
    <v-expansion-panels class="ma-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="primary">
          <div class="headline">Tips</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              v-show="!editing && assigned && eventEnd()"
              color="accent"
              class="ma-1"
              v-if="!isExported"
              @click="editingTips"
              >Edit</v-btn
            >
            <v-btn
              v-show="editing"
              class="ma-1"
              color="accent"
              @click="cancelEditing"
              >Cancel</v-btn
            >
          </v-row>
          <v-card-actions>
              <v-row v-show="assigned">
                <v-spacer></v-spacer>
                <v-col cols="4" sm="4" md="4">
                  <v-card tile>
                    <v-card-title class="body-2 headline customPadding">
                      <span>Total Tips</span>
                    </v-card-title>
                    <v-card-text class="headline customPadding">
                      {{ getNumeral(this.totalTipAmount) }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4" sm="4" md="4">
                  <v-card tile>
                    <v-card-title class="body-2 headline customPadding">
                      <span>Disbursed</span>
                    </v-card-title>
                    <v-card-text class="headline customPadding">
                      {{
                        getNumeral(
                          getTotalDisbursed()
                        )
                      }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4" sm="4" md="4">
                  <v-card tile :color="setRemainingCardColor()">
                    <v-card-title class="body-2 headline customPadding">
                      <span>Remaining</span>
                    </v-card-title>
                    <v-card-text class="headline customPadding">
                      {{
                        getRemainder()
                      }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
          </v-card-actions>
          <v-data-table
            v-show="editing"
            :headers="headers"
            :items="tipsList"
            v-model="tipsList">
            <template v-slot:top>
            <v-row class="justify-center">
              <v-card v-for="select in splitTipsSelect"
              :key="select.id" class="ma-2" flat
              :color="splitTipsColor(select)">
                <v-card-title class="headline justify-center">
                {{select.text}}
                </v-card-title>
                <v-card-text class="headline text-center">
                {{`$${select.amount}`}}
                </v-card-text>
                <v-select
                label="Split Tips"
                :items="splitTipsOptions"
                hide-details
                v-model="$data[select.model]"
                solo
                type="text"></v-select>
                </v-card>
            </v-row>
            </template>
            <template #item.name = {item}>
                {{item.name}}
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                <v-icon color="green"
                v-on="on">{{hasDriverBonus(item.userId)}}</v-icon>
                  </template>
                  <span>Driver Pay Added</span>
                </v-tooltip>
              </template>
              <template #item.gratuity = {item}>
                <v-currency-field
                          v-model.number="item.gratuity"
                          type="number"
                          dense
                          single-line
                          hide-details
                          clearable
                          prefix="$"
                          :min="0"
                          :max="getMax(item.gratuity)"
                        ></v-currency-field>
                <v-slider
                          v-model.number="item.gratuity"
                          step=".01"
                          dense
                          hide-details
                          :success="
                            getCurrentGratuitySum() >=
                              totalGratuityAmount
                          "
                          :min="0"
                          :max="getMax(item.gratuity)"
                        >
                        <template v-slot:prepend>
                            <v-icon
                              @click="decrementGratuity(item)"
                              :disabled="item.gratuity <= 0"
                            >
                              mdi-minus
                            </v-icon>
                          </template>
                          <template v-slot:append>
                            <v-icon
                              @click="incrementGratuity(item)"
                              :disabled="
                                getCurrentGratuitySum() >=
                                  totalGratuityAmount
                              "
                            >
                              mdi-plus
                            </v-icon>
                          </template>
                        </v-slider>
              </template>
              <template #item.autoGratuity = {item}>
                <v-currency-field
                          v-model.number="item.autoGratuity"
                          type="number"
                          dense
                          single-line
                          hide-details
                          clearable
                          prefix="$"
                          :min="0"
                          :max="getMax(item.autoGratuity, true)"
                        ></v-currency-field>
                <v-slider
                          v-model.number="item.autoGratuity"
                          step=".01"
                          dense
                          hide-details
                          :success="
                            getCurrentAutoGratuitySum() >=
                              totalAutoGratuityAmount
                          "
                          :min="0"
                          :max="getMax(item.autoGratuity, true)"
                        >
                        <template v-slot:prepend>
                            <v-icon
                              @click="decrementAutoGratuity(item)"
                              :disabled="item.autoGratuity <= 0"
                            >
                              mdi-minus
                            </v-icon>
                          </template>
                          <template v-slot:append>
                            <v-icon
                              @click="incrementAutoGratuity(item)"
                              :disabled="
                                getCurrentAutoGratuitySum() >=
                                  totalAutoGratuityAmount
                              "
                            >
                              mdi-plus
                            </v-icon>
                          </template>
                        </v-slider>
              </template>
              <template #item.comments = {item}>
                <v-textarea
                auto-grow
                clearable
                outlined
                dense
                rows="1"
                hide-details
                full-width
                v-model="item.comments"
                >{{item.comments}}</v-textarea>
              </template>

          </v-data-table>
          <v-card-subtitle v-show="!assigned"
            >No employees assigned to the event.
          </v-card-subtitle>
          <v-row>
            <v-container>
              <v-data-table
                :headers="headers"
                :items="tipsList"
                item-key="user.name"
                sort-by="user"
                v-show="!editing && assigned && eventEnd()"
              >
              <template #item.name = {item}>
                {{item.name}}
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                <v-icon color="green"
                v-on="on">{{hasDriverBonus(item.userId)}}</v-icon>
                  </template>
                  <span>Driver Pay Added</span>
                </v-tooltip>
              </template>
              <template #item.gratuity = {item}>
                {{getNumeral(item.gratuity)}}
              </template>
              <template #item.autoGratuity = {item}>
                {{getNumeral(item.autoGratuity)}}
              </template>
              </v-data-table>
              <v-card-subtitle
                v-show="!editing && assigned && !eventEnd()"
              >
                Cannot Assign tips before Event
              </v-card-subtitle>
            </v-container>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-show="editing"
              color="accent"
              @click="putTips()"
              >Save</v-btn
            >
          </v-card-actions>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>
<script>
import tipsApi from "@/api/tipsApi";
import numeral from "numeral";

export default {
  name: "OrderTipsAssignment",
  data() {
    return {
      totalTipAmount: 0,
      totalGratuityAmount: 0,
      totalAutoGratuityAmount: 0,
      tipsList: [],
      editing: false,
      assigned: false,
      autoGratuity: false,
      gratuity: true,
      splitAutoGratuity: "",
      splitGratuity: "",
      gratuityText: "Gratuity",
      autoGratuityText: "Auto Gratuity",
      splitTipsOptions: [
        {text: "0%", value: "0"},
        {text: "60%", value: "60"},
        {text: "70%", value: "70"},
        {text: "80%", value: "80"},
        {text: "90%", value: "90"},
        {text: "100%", value: "100"},
      ],
      splitTipsSelect: [],
    };
  },
  props: {
    order: Object,
    isExported: Boolean,
  },
  mounted() {
    tipsApi.getTipsByOrderId(this.order.cityOrderId).then((response) => {
      if (response.data) {
        this.totalTipAmount = response.data.totalTipAmount;
        if (this.order.orderUsers && this.order.orderUsers.length) {
          this.tipsList = this.buildTips(response.data.tipsList);
          this.totalGratuityAmount = this.order.gratuity;
          if (this.order.autoGratuity > 0) {
            this.totalAutoGratuityAmount = this.order.autoGratuity;
            this.autoGratuity = true;
          }
          if (!this.order.gratuity > 0) {
            this.gratuity = false;
          }
        }
        this.buildSplitTipsSelect();
      }
    });
  },
  methods: {
    putTips() {
      if (this.validation()) {
        tipsApi.putTips(this.order.id, this.tipsList).then((response) => {
          this.$emit("save", response);
          this.$root.$emit("successToast", "Tips updated.");
          this.order = response.data;
          this.tipsList = this.buildTips();
        }),
        (error) => {
          this.$root.$emit(
              "errorToast",
              "Error encountered.  Please contact support.",
          );
        };
        this.editing = false;
      } else {
        this.$emit("TipsValidation");
      }
    },
    buildTips() {
      const ret = [];
      this.order.orderUsers.forEach((employee) => {
        const existingEmployee = ret.some((tip) => {
          return tip.userId === employee.userId;
        });
        if (!existingEmployee) {
          const existing = this.order.tips.filter((t) => {
            return t.userId === employee.userId;
          });
          const tip = {
            userId: employee.userId,
            orderId: this.order.id,
            gratuity: existing.length > 0 ? existing[0].gratuity : 0,
            autoGratuity: existing.length > 0 ? existing[0].autoGratuity : 0,
            user: employee.user,
            name: employee.user.firstName + " " + employee.user.lastName,
            comments: existing.length > 0 ? existing[0].comments : "",
            // eslint-disable-next-line max-len
            total:
              existing.length > 0 ?
                this.getNumeral(
                    existing[0].gratuity + existing[0].autoGratuity) :
                "",
          };
          ret.push(tip);
        }
      });

      return ret;
    },
    eventEnd() {
      return new Date(this.order.expectedEnd) < Date.now();
    },
    editingTips() {
      this.editing = true;
    },
    cancelEditing() {
      this.editing = false;
    },
    decrementGratuity(item) {
      item.gratuity--;
    },
    incrementGratuity(item) {
      item.gratuity++;
    },
    decrementAutoGratuity(item) {
      item.autoGratuity--;
    },
    incrementAutoGratuity(item) {
      item.autoGratuity++;
    },
    getNumeral(amount) {
      return numeral(amount).format("$0,0.00");
    },
    getTotalDisbursed() {
      return +(this.getCurrentAutoGratuitySum() +
      this.getCurrentGratuitySum()).toFixed(2);
    },
    getRemainder() {
      const remainder = (this.totalTipAmount -
        this.getTotalDisbursed()).toFixed(2);
      return this.getNumeral(remainder);
    },
    // Users may have multiple assighnments but we are after a distinct list
    hasDriverBonus(userId) {
      const userIdHasDriverBonus = this.order.orderUsers.some((x) => {
        return x.userId == userId && x.isDriverOwnCar;
      });
      if (userIdHasDriverBonus) {
        return "mdi-car";
      }
    },
    getCurrentGratuitySum() {
      const min = 0;
      const max = this.totalTipAmount;
      let sum = 0;
      this.tipsList.forEach((tip) => (sum += tip.gratuity));
      let ret = sum;
      if (ret < min) {
        ret = min;
      }
      if (ret > max) {
        ret = max;
      }
      return +ret.toFixed(2);
    },
    getCurrentAutoGratuitySum() {
      const min = 0;
      const max = this.totalTipAmount;
      let sum = 0;
      this.tipsList.forEach((tip) => (sum += tip.autoGratuity));
      let ret = sum;
      if (ret < min) {
        ret = min;
      }
      if (ret > max) {
        ret = max;
      }
      return +ret.toFixed(2);
    },
    buildSplitTipsSelect() {
      if (this.totalGratuityAmount > 0 && this.totalAutoGratuityAmount > 0) {
        this.splitTipsSelect.push(
            {id: 0, text: this.gratuityText,
              amount: this.totalGratuityAmount,
              model: "splitGratuity"},
            {id: 1, text: this.autoGratuityText,
              amount: this.totalAutoGratuityAmount,
              model: "splitAutoGratuity"});
      } else if (this.totalGratuityAmount > 0) {
        this.splitTipsSelect.push(
            {id: 0, text: this.gratuityText, amount: this.totalGratuityAmount,
              model: "splitGratuity"});
      } else {
        this.splitTipsSelect.push(
            {id: 0, text: this.autoGratuityText,
              amount: this.totalAutoGratuityAmount,
              model: "splitAutoGratuity"});
      }
    },
    splitTips(percent, autoGratuity) {
      if (this.order.orderUsers) {
        if (autoGratuity) {
          const portion =
            Math.floor((this.totalAutoGratuityAmount * (percent * 0.01) /
            this.tipsList.length) * 100) / 100;
          this.tipsList.forEach((tip) => {
            tip.autoGratuity = 0;
            tip.autoGratuity += portion;
          });
        } else {
          const portion =
            Math.floor((this.totalGratuityAmount * (percent * 0.01) /
            this.tipsList.length) * 100) / 100;
          this.tipsList.forEach((tip) => {
            tip.gratuity = 0;
            tip.gratuity += portion;
          });
        }
      }
    },
    getMax(amount, autoGratuity) {
      if (autoGratuity) {
        // eslint-disable-next-line max-len
        return (
          this.totalAutoGratuityAmount -
          this.getCurrentAutoGratuitySum() +
          amount
        );
      } else {
        return this.totalGratuityAmount - this.getCurrentGratuitySum() + amount;
      }
    },
    validation() {
      let totalDisbursedTips = 0;
      this.tipsList.forEach((x) => {
        totalDisbursedTips += x.gratuity + x.autoGratuity;
      });
      return totalDisbursedTips.toFixed(2) <= this.totalTipAmount;
    },
    setRemainingCardColor() {
      const disbursedTips = +(this.getCurrentGratuitySum() +
        this.getCurrentAutoGratuitySum()).toFixed(2);
      return this.totalTipAmount === disbursedTips ? "green" : "clear";
    },
    splitTipsColor(select) {
      if (select.text === this.gratuityText) {
        return this.getCurrentGratuitySum() === select.amount ?
          "green" : "primary";
      } if (select.text === this.autoGratuityText) {
        return this.getCurrentAutoGratuitySum() === select.amount ?
          "green" : "primary";
      }
    },
  },
  computed: {
    headers() {
      if (this.totalGratuityAmount > 0 && this.totalAutoGratuityAmount > 0) {
        return [
          {text: "Employee", value: "name"},
          {text: "Total", value: "total"},
          {text: "Gratuity", value: "gratuity"},
          {text: "Service Charge", value: "autoGratuity"},
          {text: "Comments", value: "comments"},
        ];
      } else if (this.totalGratuityAmount > 0) {
        return [
          {text: "Employee", value: "name"},
          {text: "Gratuity", value: "gratuity"},
          {text: "Comments", value: "comments"},
        ];
      } else {
        return [
          {text: "Employee", value: "name"},
          {text: "Auto Gratuity", value: "autoGratuity"},
          {text: "Comments", value: "comments"},
        ];
      }
    },
  },
  watch: {
    tipsList() {
      this.assigned = this.tipsList.length > 0;
    },
    splitGratuity() {
      this.splitTips(this.splitGratuity);
    },
    splitAutoGratuity() {
      this.splitTips(this.splitAutoGratuity, true);
    },
  },
};
</script>

<style scoped>
.customPadding {
  padding: 3px 10px;
}
</style>
