import Vue from "vue";

export default {
  getOrdersVm() {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Orders/`);
  },
  getFilteredOrders(orderFilters) {
    return Vue.axios.post(`${process.env.VUE_APP_API_BASE}/Orders/` +
        "GetNewFilteredOrders/", orderFilters);
  },
  getScheduleVm() {
    return Vue.axios.get(`${process.env.VUE_APP_API_BASE}/Orders/Schedule/`);
  },
  putOrder(order) {
    return Vue.axios.put(
        `${process.env.VUE_APP_API_BASE}/Orders/${order.id}`,
        order,
    );
  },
  putETLCityOrders(orders) {
    return Vue.axios.put(
        `${process.env.VUE_APP_API_BASE}/PutETLOrders/`,
        orders,
    );
  },
  getOrderById(orderId) {
    return Vue.axios.get(
        `${process.env.VUE_APP_API_BASE}/Orders/GetOrderById/${orderId}`,
    );
  },
  getScheduleOrderById(orderId) {
    return Vue.axios.get(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Orders/GetScheduleOrderById/${orderId}`,
    );
  },
  getMarketList() {
    return Vue.axios.get(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Orders/GetMarketList`,
    );
  },
  getEmployeesList() {
    return Vue.axios.get(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Orders/GetEmployeesList`,
    );
  },
  getOrderFormatList() {
    return Vue.axios.get(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Orders/GetOrderFormatList`,
    );
  },
  getScheduleOrders(orderFilters) {
    return Vue.axios.get(
        // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Orders/GetScheduleOrders?StartDate=${orderFilters.startDate}&EndDate=${orderFilters.endDate}`,
    );
  },
  getRefreshOrder(data) {
    return Vue.axios.get(
    // eslint-disable-next-line max-len
        `${process.env.VUE_APP_API_BASE}/Orders/OrderRefresh?Order=${data.order}&User="${data.user}"&Email="${data.email}"`,
    );
  },
  chitRePrint(orderId, processId) {
    return Vue.axios.post(`${process.env.VUE_APP_API_BASE}/Orders/` +
        `ChitRePrint?OrderId=${orderId}&ProcessId=${processId}`);
  },
};
