<template>
  <v-layout column class="ma-2">
    <v-card tile>
      <v-data-iterator :items="assignmentsList"
      :hide-default-footer="assignmentsList.length == 0">
        <template v-slot:header>
          <v-toolbar color="primary" dense>
            <span class="headline">Employee Assignments</span>
            <v-badge
              v-if="assignmentsList.length"
              color="accent"
              class="justify-start"
              :content="assignmentsList.length"
              inline
              bordered
            ></v-badge>
            <v-spacer></v-spacer>
            <v-btn color="accent"
            v-if="!isExported"
             :disabled="!valid && assignmentsList.length > 0"
             @click="addAssignment">Add</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:default="props">
          <v-form v-model="valid"
          ref="form" class="pt-3 assignments-form">
            <v-row
              v-for="(item, index) in props.items"
              :key="index"
              dense
              class="px-3 border-bottom"
            >
              <v-col v-if="isAdminAssignments && !selectedLocation">
                <v-select
                  :items="allLocationsList"
                  item-text="description"
                  item-value="id"
                  v-model="item.locationId"
                  placeholder="Location"
                  clearable
                  :rules="[rules.required]"
                  dense
                >
                </v-select>
              </v-col>
              <v-col v-if="!isAdminAssignments && item.isNewEntry">
                <v-switch
                  color="accent"
                  :disabled="isExported"
                  class="ma-0"
                  v-model="item.isTerminated"
                  v-on:change="checkEmpStatus(item.isTerminated ?
                  'Terminated' : 'Active')"
                  :label="item.isTerminated ? 'All' : 'Active'"
                ></v-switch>
              </v-col>
              <v-col v-else-if="!isAdminAssignments">
                <v-switch
                  color="accent"
                  disabled
                  class="ma-0"
                  :input-value="getCurrentEmpStatus(item)"
                  v-on:change="checkEmpStatus(item.isTerminated ?
                  'Terminated' : 'Active')"
                  :label="getCurrentEmpStatus(item) ? 'All' : 'Active'"
                ></v-switch>
              </v-col>
              <v-col v-if="item.isNewEntry && !isAdminAssignments
              && item.isNewRow">
                <v-autocomplete
                  :disabled="isAdminAssignments && !item.locationId
                  || isExported"
                  :items="getNewEmployees"
                  item-status="status"
                  item-text="name"
                  item-value="id"
                  v-model="item.userId"
                  v-on:change="getUserOrders(item.userId)"
                  placeholder="New"
                  clearable
                  dense
                  :rules="[rules.required]"
                >
                </v-autocomplete>
              </v-col>
              <v-col v-else>
                <v-autocomplete
                  :disabled="isAdminAssignments && !item.locationId
                  || isExported"
                  :items="getEmployees(item)"
                  item-text="name"
                  item-value="id"
                  v-model="item.userId"
                  placeholder="Employee"
                  clearable
                  dense
                  :rules="[rules.required]"
                >
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-select
                  :disabled="isAdminAssignments && !item.locationId
                  || isExported"
                  :items="getJobCodes(item)"
                  @input="changeJobCode(item)"
                  item-text="description"
                  item-value="id"
                  v-model="item.jobCodeId"
                  placeholder="Job"
                  clearable
                  :rules="[rules.required]"
                  dense
                >
                </v-select>
              </v-col>
              <v-col v-if="!isAdminAssignments">
                <v-switch
                  color="accent"
                  :disabled="isExported"
                  class="ma-0"
                  v-model="item.isLead"
                  :label="item.isLead ? 'Lead' : 'Not Lead'"
                ></v-switch>
              </v-col>
              <v-col  v-if="!isAdminAssignments">
                <v-select
                  ref="drivers"
                  :items="drivers"
                  :disabled="isExported"
                  v-model="item.driver"
                  v-on:change="checkIsOwnCar($event, item)"
                  placeholder="Not Driving"
                  clearable
                  dense
                ></v-select>
              </v-col>
              <v-col v-if="isAdminAssignments">
                <v-text-field
                  type="date"
                  v-model="item.date"
                  label="Date"
                  dense
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col >
                <v-text-field
                  pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                  type="time"
                  v-model="item.startTime"
                  :disabled="isExported"
                  label="Start"
                  dense
                  :rules="[
                    rules.required,
                    beforeEnd(item.startTime, item.endTime),
                    noOverlap(item.userId, item.startTime, item.date, 'start'),
                  ]"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                  type="time"
                  v-model="item.endTime"
                  :disabled="isExported"
                  label="End"
                  dense
                  :rules="[
                    rules.required,
                    afterStart(item.endTime, item.startTime),
                    noOverlap(item.userId, item.endTime, item.date, 'end'),
                  ]"
                >
                </v-text-field>
              </v-col>
              <v-btn
                  large
                  icon
                  v-if="!isExported"
                  color="accent"
                  @click="removeAssignment(index)"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-row>
          </v-form>
        </template>
        <template v-slot:footer>
          <v-toolbar color="primary" dense>
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              @click="putAssignments"
              v-if="!isExported"
              :class="{
                accent: valid,
              }"
              :disabled="!valid && assignmentsList.length"
              >Save</v-btn
            >
            <v-btn
              color="accent"
              @click="cancel"
              v-if="calledFrom == 'Grid'"
              :class="{
                accent: valid, cancel
              }"
              >Cancel</v-btn
            >
          </v-toolbar>
        </template>
      </v-data-iterator>
    </v-card>
  </v-layout>
</template>
<script>
import assignmentsApi from "@/api/assignmentsApi";
import UserTime from "@/helpers/userTime.js";
import moment from "moment";
import Vue from "vue";

export default {
  name: "Assignments",
  data() {
    return {
      allJobCodesList: [],
      allLocationsList: [],
      allEmployeesList: [],
      assignmentsList: [],
      allAssignmentsList: [],
      newEmpList: [],
      currentEmployees: [],
      driverOwnCarValue: 1,
      driverCompanyCarValue: 2,
      terminatedEmp: false,
      addDisable: false,
      blankAssignment: {
        isNewEntry: true,
        orderId: "",
        userId: "",
        isTerminated: false,
        isDriver: false,
        isDriverOwnCar: false,
        isLead: false,
        jobCodeId: 0,
        locationId: null,
        date: "",
        start: "",
        startTime: "",
        end: "",
        endTime: "",
        driver: "",
        isNewRow: true,
      },
      valid: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      currentEmployeeOrders: [],
    };
  },
  props: {
    order: Object,
    isAdminAssignments: Boolean,
    isExported: Boolean,
    selectedLocation: Number,
    calledFrom: String,
  },
  watch: {
    order: {
      handler: function(val, oldVal) {
        this.getAssignments();
      },
    },
    selectedLocation() {
      this.filterAssignments();
    },
  },
  mounted() {
    if (this.calledFrom == "Grid" || this.isAdminAssignments) {
      this.getAssignments();
    }
  },
  methods: {
    checkIsOwnCar(event, item) {
      let isOwnCar;
      if (event === 1) {
        isOwnCar = true;
      }
      if (this.assignmentsList.length > 1) {
        // eslint-disable-next-line max-len
        const newList = this.assignmentsList.filter((i) => i.isNewEntry !== true);
        newList.forEach((i) => {
          if (item.userId === i.userId && i.isDriverOwnCar === isOwnCar) {
            Vue.swal({
              // eslint-disable-next-line max-len
              title: "Action Not Allowed",
              text: "Cannot select 'Own Car' twice for same order",
              type: "warning",
              showCancelButton: false,
              confirmButtonColor: "accent",
              confirmButtonText: "Ok",
              closeOnConfirm: false,
            });
            this.$refs["drivers"][0].reset();
          }
        });
        // eslint-disable-next-line max-len
        const newEntry = this.assignmentsList.filter((i) => i.isNewEntry == true);
        // eslint-disable-next-line max-len
        newEntry.forEach((n) => {
          if (item.userId === n.userId && n.isDriverOwnCar === isOwnCar) {
            Vue.swal({
              // eslint-disable-next-line max-len
              title: "Action Not Allowed",
              text: "Cannot select 'Own Car' twice for same order",
              type: "warning",
              showCancelButton: false,
              confirmButtonColor: "accent",
              confirmButtonText: "Ok",
              closeOnConfirm: false,
            });
            this.$refs["drivers"][0].reset();
          }
        });
      } else {
        this.assignmentsList[0].isDriverOwnCar = true;
      }
    },
    checkEmpStatus(status) {
      if (status === "Active") {
        // eslint-disable-next-line max-len
        this.currentEmployees = this.newEmpList.filter((i) => i.status == status);
      } else {
        this.currentEmployees = this.newEmpList.filter((i) => i);
      }
    },
    getUserOrders(userId) {
      if (userId && this.order.startDateTime) {
        assignmentsApi
            .getUserAssignmentsByUserId(userId, this.order.startDateTime)
            .then((response) => {
              this.currentEmployeeOrders = response.data;
            });
      }
    },
    changeJobCode(item) {
      const currentStatus = item.status ? "Active" : "Terminated";
      if (item.jobCodeId) {
        this.currentEmployees = this.currentEmployees.filter((e) =>
          e.cityJobCodes.some((o) => o.id == item.jobCodeId));
      } else {
        if (currentStatus == "Active") {
          // eslint-disable-next-line max-len
          this.currentEmployees = this.newEmpList.filter((i) => i.status == currentStatus);
        } else {
          this.currentEmployees = this.newEmpList.filter((i) => i);
        }
      }
    },
    noOverlap(userId, timestamp, dt, u) {
      if (this.assignmentsList.length) {
        const userAssignments = this.assignmentsList.filter((assn) => {
          return assn.userId === userId;
        });
        if (userAssignments.length && timestamp) {
          let overlaps = false;
          const anyOverlaps = this.isAdminAssignments ?
            userAssignments.filter((ua) => {
              const existingStart = this.getDateTimeFromDateAndTime(
                  UserTime.getShortDate(ua.date),
                  ua.startTime,
              );
              const existingEnd = this.getDateTimeFromDateAndTime(
                  UserTime.getShortDate(ua.date),
                  ua.endTime,
              );
              const datetime = this.getDateTimeFromDateAndTime(
                  UserTime.getShortDate(dt),
                  timestamp, "HH:mm",
              );
              return datetime.isBetween(existingStart, existingEnd);
            }).length > 0 :
            userAssignments.filter((ua) => {
              const existingStart = moment(ua.startTime, "HH:mm");
              const existingEnd = moment(ua.endTime, "HH:mm");
              const time = moment(timestamp, "HH:mm");
              return moment(time).isBetween(existingStart, existingEnd);
            }).length > 0;
          if (this.currentEmployeeOrders.length > 0) {
            overlaps = this.currentEmployeeOrders.filter((ua) => {
              let start = ua.start.split("T")[1];
              start = start.substring(0, start.lastIndexOf(":"));
              let end = ua.end.split("T")[1];
              end = end.substring(0, end.lastIndexOf(":"));
              const existingStart = moment(start, "HH:mm");
              const existingEnd = moment(end, "HH:mm");
              const time = moment(timestamp, "HH:mm");
              return moment(time).isBetween(existingStart, existingEnd);
            }).length > 0;
          }

          if (anyOverlaps || overlaps) {
            return "Overlapping assignments.";
          }
        }
      }
      return true;
    },
    beforeEnd(value, end) {
      if (
        !end ||
        !value ||
        moment(value, "HH:mm").isBefore(moment(end, "HH:mm"))
      ) {
        return true;
      } else {
        return "Start is after End.";
      }
    },
    afterStart(value, start) {
      if (
        !start ||
        !value ||
        moment(value, "HH:mm").isAfter(moment(start, "HH:mm"))
      ) {
        return true;
      } else {
        return "End is before Start.";
      }
    },
    filterAssignments() {
      if (this.isAdminAssignments && this.selectedLocation) {
        this.assignmentsList = [];
        this.allAssignmentsList.forEach((x) => {
          if (x.locationId === this.selectedLocation) {
            this.assignmentsList.push(x);
          }
        });
      } else {
        this.assignmentsList = this.allAssignmentsList;
      }
    },
    getAssignments() {
      if (this.isAdminAssignments) {
        assignmentsApi.getAdminAssignments().then((response) => {
          this.allEmployeesList = response.data.allEmployeesList;
          this.setEmployeeNames(this.allEmployeesList);
          this.allJobCodesList = response.data.allJobCodesList;
          this.allLocationsList = response.data.allLocationsList;
          this.allAssignmentsList = this.buildAssignments(
              response.data.assignmentsList,
          );
          this.filterAssignments();
        });
      } else {
        assignmentsApi
            .getAssignments(this.order.cityOrderId)
            .then((response) => {
              this.allEmployeesList = response.data.allEmployeesList;
              this.setEmployeeNames(this.allEmployeesList);
              this.allJobCodesList = response.data.allJobCodesList;
              this.assignmentsList = this.buildAssignments(
                  response.data.assignmentsList,
              );
              this.newEmpList = response.data.allEmployeesList;
            });
      }
    },
    setEmployeeNames(users) {
      if (users) {
        users.forEach((user) => {
          user.name = `${user.firstName} ${user.lastName}`;
        });
      }
    },
    buildAssignments(assignments) {
      const ret = [];
      if (assignments) {
        assignments.forEach((assignment) => {
          ret.push({
            id: assignment.id,
            orderId: assignment.orderId,
            userId: assignment.userId,
            isDriver: assignment.isDriver,
            isDriverOwnCar: assignment.isDriverOwnCar,
            isLead: assignment.isLead,
            jobCodeId: assignment.jobCodeId,
            locationId: assignment.locationId,
            start: assignment.start,
            startTime: UserTime.get24HourTime(assignment.start),
            end: assignment.end,
            endTime: UserTime.get24HourTime(assignment.end),
            date: UserTime.getIso8601Date(assignment.start),
            driver: assignment.isDriver ?
              this.driverCompanyCarValue :
              assignment.isDriverOwnCar ?
              this.driverOwnCarValue :
              "",
          });
        });
      }
      return ret;
    },
    putAssignments() {
      if (!this.assignmentsList.length || this.$refs.form.validate()) {
        this.assignmentsList.forEach((assn) => {
          assn.orderId = this.isAdminAssignments ? null : this.order.id;
          assn.cityOrderId = this.isAdminAssignments ?
            null :
            this.order.cityOrderId;
          assn.isDriver = assn.driver == this.driverCompanyCarValue;
          assn.isDriverOwnCar = assn.driver == this.driverOwnCarValue;
          assn.start = this.isAdminAssignments ?
            this.getDateTimeFromDateAndTime(assn.date, assn.startTime) :
            this.getDateTimeFromDateAndTime(
                UserTime.getShortDate(this.order.startDateTime),
                assn.startTime,
            );
          assn.end = this.isAdminAssignments ?
            this.getDateTimeFromDateAndTime(assn.date, assn.endTime) :
            this.getDateTimeFromDateAndTime(
                UserTime.getShortDate(this.order.startDateTime),
                assn.endTime,
            );
        });
        if (this.isAdminAssignments) {
          assignmentsApi
              .putAdminAssignments(this.assignmentsList)
              .then((response) => {
                this.getAssignments(response.data);
                this.$root.$emit("successToast", "Assignments updated.");
              }),
          (error) => {
            this.$root.$emit(
                "errorToast",
                "Error encountered.  Please contact support.",
            );
          };
        } else {
          assignmentsApi
              .putAssignments(this.order.cityOrderId, this.assignmentsList)
              .then((response) => {
                this.$root.$emit("successToast", "Assignments updated.");
                this.addDisable = false;
                if (this.calledFrom != "Grid") {
                  location.reload(true);
                } else {
                  this.$emit("updatePage");
                }
              }),
          (error) => {
            this.$root.$emit(
                "errorToast",
                "Error encountered.  Please contact support.",
            );
          };
        }
      }
      if (this.calledFrom = "Grid") {
        this.$emit("pullOrders");
      }
    },
    removeAssignment(index) {
      if (this.isAdminAssignments && this.assignmentsList[index].id) {
        assignmentsApi
            .removeAdminAssignment(this.assignmentsList[index].id)
            .then((response) => {
              this.assignmentsList.splice(index, 1);
            });
        this.addDisable = false;
      } else {
        this.assignmentsList.splice(index, 1);
        this.addDisable = false;
      }
    },
    addAssignment() {
      this.assignmentsList.map((al) => {
        al.isNewRow = false;
        return al;
      });
      if (this.isAdminAssignments && this.selectedLocation) {
        const blankAssignment = Object.assign({}, this.blankAssignment);
        blankAssignment.locationId = this.selectedLocation;
        this.assignmentsList.unshift(Object.assign({}, blankAssignment));
      } else {
        this.assignmentsList.unshift(Object.assign({}, this.blankAssignment));
        // eslint-disable-next-line max-len
        this.currentEmployees = this.newEmpList.filter((i) => i.status == "Active");
        this.addDisable = true;
      }
    },
    getDateTimeFromDateAndTime(date, time) {
      return moment.utc(`${date} ${time}`);
    },
    getJobCodes(assignment) {
      if (!assignment.userId) return this.allJobCodesList;
      const ret = [];
      if (assignment) {
        const employee = this.allEmployeesList.filter((employee) => {
          return employee.id === assignment.userId;
        });
        if (employee && employee.length && employee[0].cityJobCodes) {
          employee[0].cityJobCodes.forEach((jc) => {
            ret.push({
              id: jc.id,
              description: jc.description,
            });
          });
        }
      }
      return ret;
    },
    getEmployees(assignment) {
      const ret = [];
      if (this.isAdminAssignments) {
        if (!assignment.locationId && !assignment.jobCodeId) {
          return this.allEmployeesList;
        } else if (!assignment.locationId) {
          // Filter by job code selection
          this.allEmployeesList.forEach((employee) => {
            if (
              employee.cityJobCodes.filter((jc) => {
                return assignment.jobCodeId === jc.id;
              }).length
            ) {
              ret.push({
                id: employee.id,
                name: `${employee.firstName} ${employee.lastName}`,
              });
            }
          });
        } else if (!assignment.jobCodeId) {
          // Filter by location selection
          this.allEmployeesList.forEach((employee) => {
            if (
              employee.cityLocations.filter((l) => {
                return assignment.locationId === l.id;
              }).length
            ) {
              ret.push({
                id: employee.id,
                name: `${employee.firstName} ${employee.lastName}`,
              });
            }
          });
        } else {
          this.allEmployeesList.forEach((employee) => {
            if (
              employee.cityLocations.filter((l) => {
                return assignment.locationId === l.id;
              }).length &&
              employee.cityJobCodes.filter((jc) => {
                return assignment.jobCodeId === jc.id;
              }).length
            ) {
              // Filter by both location and job code selections
              ret.push({
                id: employee.id,
                name: `${employee.firstName} ${employee.lastName}`,
              });
            }
          });
        }
      } else {
        if (!assignment.jobCodeId) return this.allEmployeesList;
        this.allEmployeesList.forEach((employee) => {
          if (
            employee.cityJobCodes.filter((jc) => {
              return assignment.jobCodeId === jc.id;
            }).length
          ) {
            if (employee.status == "Active" && this.isAdminAssignments) {
              ret.push({
                id: employee.id,
                name: `${employee.firstName} ${employee.lastName}`,
                status: employee.status,
              });
            } else if (!this.isNewEntry && employee.status == "Active") {
              ret.push({
                id: employee.id,
                name: `${employee.firstName} ${employee.lastName}`,
                status: employee.status,
              });
            } else {
              ret.push({
                id: employee.id,
                name: `${employee.firstName} ${employee.lastName}`,
                status: employee.status,
              });
            }
          }
        });
      }
      return ret;
    },
    getCurrentEmpStatus(assignment) {
      const data = this.getEmployees(assignment);
      const employeeData = data.filter((d) => d.id === assignment.userId);
      // eslint-disable-next-line max-len
      if (employeeData[0] && employeeData[0].status && employeeData[0].status === "Active") {
        return false;
      } else {
        return true;
      }
    },
    cancel() {
      this.$emit("close");
    },
  },
  computed: {
    drivers() {
      return [
        {text: "Own Car", value: this.driverOwnCarValue},
        {text: "Company Car", value: this.driverCompanyCarValue},
      ];
    },
    today() {
      return UserTime.getIso8601Date(moment().startOf("day"));
    },
    getNewEmployees() {
      return this.currentEmployees;
    },
  },
};
</script>
<style>
.assignments-form {
  overflow-x: hidden;
}
.cancel {
  margin-left: 10px;
}
.border-bottom {
  @media only screen and (max-width: 600px) {
    border-bottom: 1px solid #6f6b6b;
  }
}
</style>
